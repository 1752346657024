import axios from "axios";

export const getCometToken = () => {
  const cmtToken = window.cometToken ? window.cometToken() : "";
  return cmtToken;
};
export const CometlySignUp = (username) => {
  const c_token = getCometToken();
  let url_params = new URLSearchParams({
    username: username,
    comet_token: c_token
  });
  axios.post(
    "https://t.cometlytrack.com/3377699766000056/webhook/01jg3nmhze1a30251g8zraqt1x",
    url_params
  );
};

export const CometlyPurchase = (username, amount, currencyCode, planId) => {
  const c_token = getCometToken();
  let url_params = new URLSearchParams({
    username: username,
    amount: amount,
    currency_code: currencyCode,
    plan_id: planId,
    comet_token: c_token
  });
  axios.post(
    "https://t.cometlytrack.com/3377699766000056/webhook/01jg3ny256sxz25rkzst0sq9xs",
    url_params
  );
};

export const CometlyEcomLinked = (username) => {
  const c_token = getCometToken();
  let url_params = new URLSearchParams({
    username: username,
    comet_token: c_token
  });
  axios.post(
    "https://t.cometlytrack.com/3377699766000056/webhook/01jg3pd27nwd34ynrjqxrdxj43",
    url_params
  );
};

export const CometlyCheckout = (username) => {
  const c_token = getCometToken();
  let url_params = new URLSearchParams({
    username: username,
    comet_token: c_token
  });
  axios.post(
    "https://t.cometlytrack.com/3377699766000056/webhook/01jg3px1kbgxyrtq0aw84pchms",
    url_params
  );
};
