import { createTheme } from "@mui/material/styles";

interface NeutralColors {
  [key: number]: string;
}

interface PrimarySecondary {
  main: string;
}

interface Gradient {
  main: string;
}

interface rgb {
  red: string;
  green: string;
  yellow: string;
  draft: string;
  //below colors are used in posttype icons eg: short_video, single_image etc
  orange: string;
  green2: string;
  red1: string;
  green3: string;
  blue2: string;
  lightBlue: string;
  blue3: string;
  yellowBg: string;
  purple1: string;
  orange2: string;
  orangeGold: string;
  lightPink: string;
  grey: string;
  lightGold: string;
  yellowBorder: string; //used on cards border
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    smalllaptop: true;
  }
}

interface Typography {
  font1: string;
  font2: string;
  fontFamily: string;
  body2: {
    color: string;
    fontWeight: number;
  };
  body1: {
    color: string;
    fontWeight: number;
  };
  h1: {
    color: string;
    fontWeight: number;
  };
  h2: {
    color: string;
    fontWeight: number;
  };
  h3: {
    color: string;
    fontWeight: number;
  };
  h4: {
    color: string;
    fontWeight: number;
  };
  h5: {
    color: string;
    fontWeight: number;
  };
  h6: {
    color: string;
    fontWeight: number;
  };
}

// declare module "@mui/material/styles" {
//   interface Theme {
//     typography: Typography;
//   }
//   interface ThemeOptions {
//     typography?: Typography;
//   }
// }

interface FontColor {
  font2: string;
  font3: string;
  darkFont1: string;
  darkFont2: string;
  dangerDefualt: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    gradient?: Gradient;
  }
  interface PaletteOptions {
    gradient?: Gradient;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    fontColor?: FontColor;
    rgbColor?: rgb;
  }
  interface PaletteOptions {
    fontColor?: FontColor;
    rgbColor?: rgb;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    neutralColors?: NeutralColors;
  }
  interface PaletteOptions {
    neutralColors?: NeutralColors;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    primarySecondary?: PrimarySecondary;
  }
  interface PaletteOptions {
    primarySecondary?: PrimarySecondary;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    gradientPalette: {
      gradient: {
        main: string;
      };
    };
  }
  interface ThemeOptions {
    gradientPalette?: {
      gradient?: {
        main?: string;
      };
    };
  }
}

const newTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      // main: "#017FFD",
      main: "#1A6AFF",
      light: "#4183FF",
      dark: "#10419C"
    },
    secondary: {
      main: "#001F3C"
    },

    primarySecondary: {
      main: "#001F3C"
    } as PrimarySecondary,
    fontColor: {
      font2: "#747189",
      font3: "#575F78",
      darkFont1: "#DCDEE2",
      darkFont2: "#8B8F9F",
      dangerDefualt: "#CF1322"
    } as FontColor,
    // grey: {
    //   // 25: "#E7E8EA",
    //   // 25: "#F3F5F6",
    //   50: "#9DA2AA",
    //   100: "#747B86",
    //   200: "#394352",
    //   300: "#101C2F",
    //   400: "#0B1421",
    //   500: "#0A111D",
    //   600: "#F6F7F9"
    // },
    text: {
      primary: "#001F3C", //secondaryPrimary
      secondary: "#414962", //font3
      disabled: "#aaa"
    },
    divider: "#DADBE0",
    neutralColors: {
      25: "#F7F7F8",
      50: "#f5f6f7",
      100: "#ecedee",
      200: "#e0e1e4",
      300: "#c3c6cb",
      400: "#b5b9bf",
      500: "#a9adb4",
      600: "#F6F7F9"
    } as NeutralColors,
    error: {
      100: "#FFBCC8", //alert error color temp
      200: "#FECDCA",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318"
    },
    warning: {
      100: "#FEDF89",
      200: "#FEC84B",
      300: "#FDB022",
      400: "#F79009",
      500: "#DC6803",
      600: "#B54708",
      700: "#93370D"
    },
    success: {
      100: "#ECFBF4", //alert bgcolor temp
      200: "#A1E9C6", //alert bordercolor temp
      300: "#32D583",
      400: "#12B76A",
      500: "#039855",
      600: "#027A48",
      700: "#05603A"
    },
    rgbColor: {
      red: "#FF8198",
      green: "#01D3A0",
      yellow: "#4183FF",
      draft: "#CCD0DC",
      orange: "#FF4404", //special_day
      green2: "#27AE60", //single_image
      red1: "#EB5757", //carousel
      green3: "#027A48",
      blue2: "#0086C9",
      lightBlue: "#E3EDFF",
      blue3: "#E0F2FE",
      purple1: "#9B51E0",
      yellowBg: "#fff2cc",
      orange2: "#F2994A", //video_with_voice
      yellowBorder: "#F2C94C", //static user ecom cards border
      orangeGold: "#EC9006",
      lightPink: "#FEE4E2",
      grey: "#F2F4F7",
      lightGold: "#FDF8EA"
    },
    // gradient: {
    //   main: "linear-gradient(91.48deg, #016BDF 22.36%, #D52B5B 96.39%)",
    //   light: "",
    //   dark: ""
    //   // main: "#001F3C"
    // } as Gradient
    background: {
      default: "#F6F7F9",
      paper: "#FFFFFF"
    }
  },
  gradientPalette: {
    gradient: {
      main: "linear-gradient(91.48deg, #016BDF 22.36%, #D52B5B 96.39%)"
    }
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1536
  //   }
  // },
  typography: {
    fontFamily: "Hanken Grotesk",
    body2: {
      // fontFamily: "HK Grotesk",
      color: "#747189" //font2
    },
    body1: {
      // fontFamily: "HK Grotesk",
      color: "#414962", //font3
      fontWeight: 400
    },
    h1: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    },
    h2: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    },
    h3: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    },
    h4: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    },
    h5: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    },
    h6: {
      // fontFamily: "HK Grotesk",
      color: "#001F3C" //secondaryPrimary
    }
  } as Typography
});

export default newTheme;
