import { createSlice } from "@reduxjs/toolkit";
import { localStorageSafe } from "../../../utils/localStorageUtils";

const initialState = {
  sideDrawerOpen: false,
  desktopSideDrawerOpen: true,
  polotnoFullscreen: false,
  playVideo: false,
  alertMsg: "",
  alertOpen: false,
  alertClickFn: "",
  demoVideoOpen: localStorageSafe("getItem", "onboarding_version") === "F2" ? true : false,
  firstTimeLogin: localStorageSafe("getItem", "is_signup") === "true" ? true : false,
  alertType: "",
  isExpiredClosable: false,
  createPostOpen: false,
  showBackdropLoader: false,
  isWebView:
    new URLSearchParams(window.location.search).get("v") === "webview" ||
    localStorageSafe("getItem", "isWebview") === "true",
  loadEditPolotno: new URLSearchParams(window.location.search).get("v") !== "webview",
  postTypeChips: [
    {
      label: "Idea",
      value: "idea",
      active: true
    },
    {
      label: "Business",
      value: "business",
      active: false
    },
    {
      label: "Meme",
      value: "meme",
      active: false
    },
    {
      label: "E-Commerce",
      value: "ecom",
      active: false
    },
    {
      label: "Quotes",
      value: "quote",
      active: false
    },
    {
      label: "Special Day",
      value: "special",
      active: false
    },
    {
      label: "Manual",
      value: "manual",
      active: false
    },
    {
      label: "Multi-line Script",
      value: "script",
      active: false
    },
    {
      label: "Blog to Post",
      value: "blog",
      active: false
    }
  ],
  topbarHeight: 0,
  postTypeDialogOpen: false
};
const setDesktopSideDrawerOpen = (state, action) => {
  state.desktopSideDrawerOpen = action.payload;
};
const setLoadEditPolotno = (state, action) => {
  state.loadEditPolotno = action.payload;
};
const toggleSideDrawerMobile = (state) => {
  state.sideDrawerOpen = !state.sideDrawerOpen;
};
const toggleDemoVideo = (state) => {
  state.demoVideoOpen = !state.demoVideoOpen;
};
const setIsExpiredClosable = (state, action) => {
  state.isExpiredClosable = action.payload;
};
const toggleBackdropLoader = (state) => {
  state.showBackdropLoader = !state.showBackdropLoader;
};
const setPolotnoFullscreen = (state, action) => {
  state.polotnoFullscreen = action.payload;
};
const togglePolotnoFullscreen = (state) => {
  state.polotnoFullscreen = !state.polotnoFullscreen;
};
const setPlayVideo = (state, action) => {
  state.playVideo = action.payload;
};
const showAlert = (state, action) => {
  state.alertOpen = true;
  state.alertMsg = action.payload;
  state.alertType = action.alertType;
  if (action.alertTime) {
    state.alertTime = action.alertTime;
  } else {
    state.alertTime = undefined;
  }

  if (action?.onClick) {
    state.alertClickFn = action?.onClick;
  }
};
const alertCloseFn = (state) => {
  state.alertOpen = false;
  state.alertMsg = "";
  state.alertType = "";
  state.alertClickFn = "";
};
const setCreatePostOpen = (state, action) => {
  state.createPostOpen = action.payload;
};
const setActivePostType = (state, action) => {
  const arr = [...state.postTypeChips];
  arr.find((el) => el.value === action.payload).active = true;
  arr.filter((el) => el.value !== action.payload).map((el) => (el.active = false));
  state.postTypeChips = arr;
};
const setTopBarHeight = (state, action) => {
  state.topbarHeight = action.payload;
};

const setPostTypeDialogOpen = (state, action) => {
  state.postTypeDialogOpen = action.payload;
};

const uiStates = createSlice({
  name: "uiStates",
  initialState,
  reducers: {
    toggleSideDrawerMobile,
    togglePolotnoFullscreen,
    setPlayVideo,
    setDesktopSideDrawerOpen,
    showAlert,
    setLoadEditPolotno,
    alertCloseFn,
    setCreatePostOpen,
    toggleDemoVideo,
    setActivePostType,
    toggleBackdropLoader,
    setIsExpiredClosable,
    setPolotnoFullscreen,
    setTopBarHeight,
    setPostTypeDialogOpen
  }
});

export default uiStates.reducer;
