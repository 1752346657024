export const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    // try to import the component
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        // TO DO
        if (error.name === "ChunkLoadError") {
          let refreshDone = false;
          try {
            refreshDone = sessionStorage.getItem("upgradeRefreshDone") === "true";
          } catch (error) {
            refreshDone = true;
          }
          if (refreshDone) {
            reject(error); // there was an error
          } else {
            try {
              sessionStorage.setItem("upgradeRefreshDone", true);
            } catch (error) {
              console.log("session storage not available");
            }
            window.location.reload();
          }
        }
      });
  });
};
export function check_inFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
